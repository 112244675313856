const instagramPosts = [
  {
    "href": "https://www.instagram.com/classyclosetsus/p/DFOAjkdJdaX/",
    "img": "/images/instagram/475048304_1695519451006255_3020930401021875120_n.jpg",
    "caption": "Photo by Classy Closets on January 24, 2025. May be an image of indoors."
  },
  {
    "href": "https://www.instagram.com/classyclosetsus/p/DFLyHR4zlU9/",
    "img": "/images/instagram/474925583_1293679305185233_7808602729712361782_n.jpg",
    "caption": "Photo by Classy Closets on January 23, 2025. May be an image of sink."
  },
  {
    "href": "https://www.instagram.com/classyclosetsus/p/DFJC95ZvZMj/",
    "img": "/images/instagram/474694334_1291466292064197_4487060957590807388_n.jpg",
    "caption": "Photo by Classy Closets on January 22, 2025. May be an image of indoors and laundromat."
  },
  {
    "href": "https://www.instagram.com/classyclosetsus/p/DFGh0SMSslQ/",
    "img": "/images/instagram/474650676_1624857258138298_592442524590796839_n.jpg",
    "caption": "Photo by Classy Closets on January 21, 2025. May be an image of indoors."
  },
  {
    "href": "https://www.instagram.com/classyclosetsus/p/DFDyhqrMTU2/",
    "img": "/images/instagram/474387387_1013789990576792_5589076139981637759_n.jpg",
    "caption": "Photo by Classy Closets on January 20, 2025. May be an image of indoors, sliding door and barn door."
  },
  {
    "href": "https://www.instagram.com/classyclosetsus/p/DE762c-S5Vb/",
    "img": "/images/instagram/473709925_18492318796033942_6898018971748833147_n.jpg",
    "caption": "Photo by Classy Closets in Park City, Utah with @classyclosetssaltlakecity. May be an image of sliding door."
  },
  {
    "href": "https://www.instagram.com/classyclosetsus/p/DE5t3zmSy-_/",
    "img": "/images/instagram/473791291_18492174766033942_1842956235741240329_n.jpg",
    "caption": "Photo by Classy Closets in Phoenix, Arizona with @sarahowensdesign, and @classyclosetsphoenix. May be an image of light switch."
  },
  {
    "href": "https://www.instagram.com/classyclosetsus/p/DE0j4uqzDis/",
    "img": "/images/instagram/473810168_18491830231033942_5321901790290092256_n.jpg",
    "caption": "Photo shared by Classy Closets on January 14, 2025 tagging @classyclosetsoc."
  },
  {
    "href": "https://www.instagram.com/classyclosetsus/p/DEx3ch7SO3I/",
    "img": "/images/instagram/473121019_18491635762033942_1831122527965310181_n.jpg",
    "caption": "Photo shared by Classy Closets on January 13, 2025 tagging @classyclosetsdfw. May be an image of kitchen island and indoors."
  },
  {
    "href": "https://www.instagram.com/classyclosetsus/p/DEqUWjgSyVW/",
    "img": "/images/instagram/473059288_18491078731033942_6693729220971102228_n.jpg",
    "caption": "Photo shared by Classy Closets on January 10, 2025 tagging @classyclosetsoc, and @dpavittdesigns. May be an image of indoors."
  },
  {
    "href": "https://www.instagram.com/classyclosetsus/p/DEn3f7KSZSY/",
    "img": "/images/instagram/473050139_18490694911033942_2393401285460589397_n.jpg",
    "caption": "Photo shared by Classy Closets on January 09, 2025 tagging @mirandafinnegandesigns, @classyclosetsaustin, and @carpentryaustintx. May be an image of indoors and sliding door."
  },
  {
    "href": "https://www.instagram.com/classyclosetsus/p/DEf9ykYzkF4/?",
    "img": "/images/instagram/472755683_18490333894033942_8602079699058208764_n.jpg",
    "caption": "Photo shared by Classy Closets on January 08, 2025 tagging @classyclosetsoc."
  }
];

export default instagramPosts;