import styled from "styled-components";
import { Button as MainButton } from "../../../components/Form/Button/styled";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const SideImage = styled.div`
  position: relative;
  width: 100%;
  height: 600px;

  @media only screen and (max-width: 768px) {
    height: 350px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 600px;
  padding: 9rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    height: auto;
    padding: 2rem;
  }
`;

export const Button = styled(MainButton)`
  margin-top: 2rem;
  align-self: flex-start;

  @media only screen and (max-width: 768px) {
    align-self: center;
    width: calc(100% - 140px);
  }
`;
