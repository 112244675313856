import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 80px 20px;
  background-image: url('/images/home_page/factory.jpg');
  background-size: cover;
  background-position: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  color: white;

  h2 {
    font-family: 'Lora', serif;
    font-size: 36px;
    margin-bottom: 60px;
    color: white;
  }
`;

export const IconRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 60px;
  margin-bottom: 60px;

  &:last-child {
    justify-content: center;
    gap: 120px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;

    &:last-child {
      gap: 40px;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
  }
`;

export const Icon = styled.img`
  width: 180px;
  height: 180px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
    margin-bottom: 15px;
  }
`;

export const SubHeading = styled.h3`
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  line-height: 1.4;
  color: white;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`; 
