import Iframe from "react-iframe";
import { Container, IframeContainer, VirtualDescription } from "./styled";
import { Row, Col } from "antd";
import Typography from "../../../components/Typography";
import { Button } from "../DesignHomeOffice/styled";
import Link from "next/link";
import useMobile from "../../../hooks/useMobile";

const VirtualSection = () => {
  const isMobile = useMobile();
  return (
    <Container>
      <Row align="middle" justify="center" gutter={0}>
        <Col order={isMobile ? 2 : 1} xs={24} md={12}>
          <VirtualDescription>
            <Typography
              fontType="header"
              color="darkGray2"
              fontWeight="bold"
              fontSize="2rem"
              fontFamily="Lora"
              textAlign="left"
            >
              Take a Virtual Tour
            </Typography>
            <Typography
              fontFamily="Roboto medium"
              textAlign="left"
            >
              You can explore every corner of the closet, from the racks to the shelves, and get a complete understanding of its layout and functionality. Whether you&apos;re looking for a walk-in closet or a reach-in closet, our 360-degree view provides a fully immersive and interactive experience, allowing you to visualize the closet as if you were standing in it.
            </Typography>
            <Link href="/360-page" passHref>
              <Button variant="outline">VIRTUAL TOURS</Button>
            </Link>
          </VirtualDescription>
        </Col>
        <Col order={isMobile ? 1 : 2} xs={24} md={12}>
          <IframeContainer>
            <Iframe 
              url={"https://momento360.com/e/u/bdc1030798264f9dbaa2bc8e45aa83c0"}
              width="100%"
              height="100%"
              id="virtual-tour-iframe"
              className="virtual-tour-iframe"
              title="virtual tour"
              allowFullScreen
              frameBorder={0}
              styles={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </IframeContainer>
        </Col>
      </Row>
    </Container>
  );
}

export default VirtualSection;