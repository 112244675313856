import styled from "styled-components";

export const SidePaddingContainer = styled.div`
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 1920px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

// ... rest of the styled components 