import React from 'react';
import { Container, ContentWrapper, IconRow, IconContainer, Icon, SubHeading } from './styled';

const FactorySection = () => {
  return (
    <Container>
      <ContentWrapper>
        <h2>The Difference is in the Details</h2>
        <IconRow>
          <IconContainer>
            <Icon src="/images/home_page/icons/design.png" alt="Design Process" />
            <SubHeading>Custom designs that fit your style, space, and needs perfectly</SubHeading>
          </IconContainer>
          <IconContainer>
            <Icon src="/images/home_page/icons/handling.png" alt="White Glove Service" />
            <SubHeading>From design to installation, we handle everything for you</SubHeading>
          </IconContainer>
          <IconContainer>
            <Icon src="/images/home_page/icons/wood.png" alt="Premium Materials" />
            <SubHeading>Luxurious, durable solutions using high-quality natural wood</SubHeading>
          </IconContainer>
        </IconRow>
        <IconRow>
        <IconContainer>
            <Icon src="/images/home_page/icons/house.png" alt="Local Service" />
            <SubHeading>Closets, kitchens, garages, and more - no space is too unique</SubHeading>
          </IconContainer>
        <IconContainer>
            <Icon src="/images/home_page/icons/construction.png" alt="Construction" />
            <SubHeading>Precision and attention to detail in every project</SubHeading>
          </IconContainer>
        </IconRow>
      </ContentWrapper>
    </Container>
  );
};

export default FactorySection; 
