import dynamic from 'next/dynamic';
import instagramPosts from '../../data/instagram';
import { SidePaddingContainer } from './styled';
const AppLayout = dynamic(() => import('../../components/AppLayout'));
const Spacer = dynamic(() => import('../../components/Content/Spacer'));
const Banner = dynamic(() => import('./UpdatedHomePage'));
const DesignHomeOffice = dynamic(() => import('./DesignHomeOffice'));
const StepsSection = dynamic(() => import('../Home/StepsSection'));
const GetStarted = dynamic(() => import('./GetStarted'));
const ProductsAndServices = dynamic(() => import('./ProductsAndServices'));
const ReviewsSection = dynamic(() => import('./Reviews'));
const MetaCommon = dynamic(() => import('../../components/Meta/MetaCommon'));
const Instagram = dynamic(() => import('../../components/instagram'));
import ContactForm from '../CabinetsPage/About/Schedule/ScheduleForm';
import Typography from '../../components/Typography';
import VirtualSection from './VirtualTour';
import WhoWeWorkWith from './WhoWeWorkWith';
import MissionStatement from './MissionStatement';
import WoodLuxurySection from './WoodLuxurySection';
import FactorySection from './FactorySection';

const ResponsiveVideo = dynamic(() => import('./VideoContainer'), { ssr: false });

export default function ClosetsHomePage() {
  return (
    <>
      <MetaCommon metaDataKey="home" />
      <AppLayout>
        <Banner />
        <Spacer size="spacing_m" />
        <ProductsAndServices />
        <Spacer size="spacing_m" />
        <ReviewsSection />
        <Spacer size="spacing_m" />
        <SidePaddingContainer>
          <Instagram posts={instagramPosts} />
        </SidePaddingContainer>
        <Spacer size="spacing_m" />
        <MissionStatement />
        <WoodLuxurySection />
        <FactorySection />
        <DesignHomeOffice />
        <VirtualSection />
        <StepsSection />
        <GetStarted formId={"get-started-home-page"} />
        <SidePaddingContainer>
        <WhoWeWorkWith />
        </SidePaddingContainer>
      </AppLayout>
    </>
  );
}
