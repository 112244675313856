import React from 'react';
import Link from 'next/link';
import { Container, ContentWrapper, VideoWrapper, TextContent, StyledButton } from './styled';

const WoodLuxurySection = () => {
  return (
    <Container>
      <ContentWrapper>
        <VideoWrapper>
          <iframe
            src="https://player.vimeo.com/video/1046845368?h=f0f8c6c2f1&autoplay=0&loop=0&title=0&byline=0&portrait=0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </VideoWrapper>
        <TextContent>
          <h2>Real Wood, Real Luxury</h2>
          <p>
            Experience the authenticity and sophistication of real wood in every Classy Closets design. 
            Watch how we turn natural materials into functional works of art.
          </p>
          <Link href="/materials-and-finishes" passHref>
            <StyledButton>EXPLORE MATERIALS</StyledButton>
          </Link>
        </TextContent>
      </ContentWrapper>
    </Container>
  );
};

export default WoodLuxurySection; 