import styled, { css } from "styled-components";
import { getCSSExternalSpace } from "../../../utils/css";

export const Button = styled.button`
  ${({ margin, padding, radius, fullWidth }) => css`
    margin: ${getCSSExternalSpace(margin || [0])};
    padding: ${getCSSExternalSpace(padding || [10, 32])};
    border-radius: ${radius || "0px"};
    border-width: 0px;
    font-size: 18px;
    cursor: pointer;
    transition: 300ms;
    white-space: nowrap;
    ${fullWidth && " text-align: center; display: block; width: 100%;"}
  `}

  ${({ variant }) =>
    (!variant || variant === "primary") &&
    css`
      background-color: ${({ theme }) => theme.colors.darkGray2};
      color: ${({ theme }) => theme.colors.white};
      font-weight: bold;

      &:hover {
        background-color: ${({ theme }) => theme.colors.prune};
      }
    `}

    ${({ variant }) =>
    variant === "outline" &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.darkGray2};
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.darkGray2};
      font-weight: bold;
      min-width: 160px;

      @media only screen and (max-width: 768px) {
        padding: 10px 20px;
        font-size: 16px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.prune};
        color: ${({ theme }) => theme.colors.white};
      }
    `}
`;
