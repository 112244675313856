import styled from "styled-components";

export const Container = styled.section`
  padding: 80px 0;
  background-color: #762738;
  color: white;
`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  width: 100%;
  height: 0;
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export const TextContent = styled.div`
  padding: 20px;

  h2 {
    font-family: "Lora";
    font-size: 2.5rem;
    margin-bottom: 20px;
    line-height: 1.2;
    color: white;
  }

  p {
    font-family: "Roboto Regular";
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 30px;
    color: rgba(255, 255, 255, 0.9);
  }
`;

export const StyledButton = styled.button`
  background-color: white;
  color: #762738;
  border: 2px solid white;
  padding: 12px 30px;
  font-family: "Roboto Bold";
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  @media only screen and (max-width: 768px) {
    display: block; // Make the button a block element
    margin: 0 auto; // Center the button horizontally
  }
  &:hover {
    background-color: transparent;
    color: white;
  }
`;