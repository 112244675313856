import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;

  button {
    border-radius: 0px;
    border: 2px solid #762738;
    margin-top: 2rem;
    align-self: flex-start;
    
    span {
      color: #762738;
    }

    @media only screen and (max-width: 768px) {
      align-self: center;
      width: calc(100% - 140px);
    }
  }
`;

export const IframeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;

  .virtual-tour-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: 768px) {
    height: 350px;
  }
`;

export const VirtualDescription = styled.div`
  width: 100%;
  height: 600px;
  padding: 9rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    align-self: flex-start;
  }

  @media only screen and (max-width: 768px) {
    height: auto;
    padding: 2rem;

    a {
      align-self: center;
    }
  }
`;