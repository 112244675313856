import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Typography from '../../../components/Typography';

const Wrapper = styled.div`
  background-color: #d9d9d9;
  width: 100%;
  margin: 0;
  padding: 1px 10%;
`;

const Title = styled.h2`
  text-align: center;
  font-family: "Lora", serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #762738;
  margin: 60px 0 40px;
  letter-spacing: normal;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    font-size: 2rem;
    margin: 40px 0 30px;
    letter-spacing: -0.5px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin: 0 auto 3rem auto;
  width: 100%;
  box-sizing: border-box;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }

  .fade-in-up {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }

  .fade-in-up.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Item = styled.div`
  padding: 0 10px 20px 10px;
  text-align: center;
`;

const Image = styled.img`
  max-width: 400px;
  height: auto;
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;

  @media only screen and (max-width: 900px) {
    max-width: 300px;
  }

  @media only screen and (max-width: 600px) {
    max-width: 200px;
  }
`;

const Heading = styled.h2`
  margin: 10px 0 0 0;
  padding: 10px 0 0 0;
  color: #000;
  font-size: 1rem;
  font-weight: medium;
  font-family: 'Roboto Medium';
  line-height: 1.2em;
  letter-spacing: 0.1px !important;
  text-align: center;
`;

const MissionStatement = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const fadeElements = entry.target.getElementsByClassName('fade-in-up');
          Array.from(fadeElements).forEach((element, index) => {
            setTimeout(() => {
              element.classList.add('visible');
            }, index * 200); // 200ms delay between each item
          });
        }
      });
    }, { threshold: 0.1 });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <Wrapper>
      <Title>
        Expertise You Can Trust, Luxury You Can Feel
      </Title>
      <Container ref={containerRef}>
        <Column>
          <Item className="fade-in-up">
            <Image src="/images/closets-home/mission/mission-1.png" alt="Industry leading Production Times" />
            <Heading>Industry Leading Production Times</Heading>
          </Item>
        </Column>
        <Column>
          <Item className="fade-in-up">
            <Image src="/images/closets-home/mission/high-quality-materials.png" alt="Over 250 High Quality Material Options" />
            <Heading>Over 250 High Quality Material Options</Heading>
          </Item>
        </Column>
        <Column>
          <Item className="fade-in-up">
            <Image src="/images/closets-home/mission/mission-3.png" alt="Custom Designed to Fit Your Space" />
            <Heading>Custom Designed to Fit Your Space</Heading>
          </Item>
        </Column>
        <Column>
          <Item className="fade-in-up">
            <Image src="/images/closets-home/mission/mission-4.png" alt="Financing Available at Most Locations" />
            <Heading>Financing Available at Most Locations</Heading>
          </Item>
        </Column>
      </Container>
    </Wrapper>
  );
};

export default MissionStatement;